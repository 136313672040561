import {
  GET_LAYERS,
  TOGGLE_LAYER
} from "./action-types";
import {
  SET_LAYERS_LOADING,
  SET_LAYERS,
  REMOVE_ACTIVE_LAYER,
  ADD_ACTIVE_LAYER
} from "./mutation-types";

import _ from "lodash";

import TileLayer from "ol/layer/Tile.js";
import TileWMS from "ol/source/TileWMS.js";

const createLayer = (layer) =>
  new TileLayer({
    source: new TileWMS({
      url: `${process.env.GEOSERVER_URL}/wms`,
      params: {
        LAYERS: layer,
        TILED: true,
        VERSION: "1.1.1",
        SRS: "EPSG:3857",
        BBOX: "{bbox-epsg-3857}",
        WIDTH: 256,
        HEIGHT: 256,
      },
      serverType: "geoserver",
    }),
    zIndex: 98,
  });

export default {
  [GET_LAYERS]({ commit, state, rootState }) {
    let map = rootState.map.map;
    let olLayers = {};
    state.activeLayers.forEach(activeLayer => {
      let olLayer = state.layers[activeLayer]?.olLayer;
      olLayers[activeLayer] = olLayer;
    });
    Object.keys(olLayers).forEach(layer => {
      commit(REMOVE_ACTIVE_LAYER, layer);
      map.removeLayer(olLayers[layer]);
    });
    olLayers = {};
    commit(SET_LAYERS_LOADING, true);
    commit(SET_LAYERS, null);
    return this.$axios
      .$get("api/geoportal/layers")
      .then((response) => {
        const olLayers = _.chain(response ?? [])
          .keyBy((l) => l.geoserver_name)
          .mapValues((layer, layerId) => ({
            ...layer,
            olLayer: createLayer(layerId),
          }))
          .value();

        commit(SET_LAYERS, olLayers);
        commit(SET_LAYERS_LOADING, false)

        return response;
      })
      .catch(() => alert("Ошибка загрузки данных слоев!"))
      .finally(() => commit(SET_LAYERS_LOADING, false));
  },
  [TOGGLE_LAYER]({ commit, state, rootState }, { layerId }) {
    if (!layerId) return;

    const olLayer = state.layers[layerId]?.olLayer;
    let map = rootState.map.map;

    if (!state.activeLayers.includes(layerId)) {
      commit(ADD_ACTIVE_LAYER, layerId);
      map.addLayer(olLayer);
      console.log(map)
      return true;
    }

    commit(REMOVE_ACTIVE_LAYER, layerId);
    map.removeLayer(olLayer);
  }
};
