//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions } from "vuex";
import { FETCH_3D_MODELS_FEATURE_AVAILABILITY } from "@ggk_module/store/models3d/action-types";
export default {
    data() {
        return {
            mainMenu: false,
            authMenu: false,
            langListShow: false,
            showModal: false,
            modalComponent: null,
        };
    },
    created() {
      if(this.$route.params?.modalComponent) {
        this.showModal = true;
        this.modalComponent = 'baseAuth';
      }
        const onClickOutside = (e) => {
            this.authMenu = this.$el.contains(e.target) && this.authMenu;
        };
        document.addEventListener("click", onClickOutside);
        this.$on("hook:beforeDestroy", () => document.removeEventListener("click", onClickOutside));
        if (this.$auth.loggedIn) this.checkAccess()
        if (this.$auth.loggedIn) this[FETCH_3D_MODELS_FEATURE_AVAILABILITY]();
    },
    computed: {
        availableLocales() {
            return this.$i18n.locales;
        },
        currentLocale() {
            return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
        },
        menuItems() {
            return this.$store.state.headerBase;
        },
        ...mapState("pageAccess", ["expertPageAccess", "checkPageAccess"]),
        ...mapState("models3d", ["is3DModelsFeatureAccessible"]),
    },
    methods: {
      ...mapActions("models3d", [FETCH_3D_MODELS_FEATURE_AVAILABILITY]),
      ...mapMutations("pageAccess", ["set_expert_access", "set_check_access"]),
      openModal() {
        if (!this.$route.query?.redirect) {
          this.$router.push({
            path: this.$route.fullPath,
            query: { redirect: this.$route.fullPath },
          });
        }
        this.modalComponent = "baseAuth";
        this.showModal = true;
      },
        async logout() {
          await this.$auth.logout();
          this.set_expert_access(false)
          this.set_check_access(false)
          this.$router.push("/base/auth/login");
        },
        changeLocale(newLocale) {
          this.$i18n.setLocale(newLocale);
          this.$nuxt.$emit("changeLocale", newLocale);
        },
        checkAccess(){
          this.$axios.$get("api/reg_app/expert/is_expert").then((res) => { 
            if(res == 1) {
              this.set_expert_access(true)
            }
          });
          this.$axios.$get("api/reg_app/access").then((res) => { 
              if(res == 1) {
                this.set_check_access(true)
              }
            }
          );
        },
    }
};
