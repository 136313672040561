import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _73bcc816 = () => interopDefault(import('../modules/ggk_module/pages/ggkauth.vue' /* webpackChunkName: "" */))
const _714a6402 = () => interopDefault(import('../modules/ggk_module/pages/base/sso.vue' /* webpackChunkName: "" */))
const _1eff4c50 = () => interopDefault(import('../modules/ggk_module/pages/base/reg_app/get/_id.vue' /* webpackChunkName: "" */))
const _bc816d7c = () => interopDefault(import('../modules/ggk_module/pages/base/index.vue' /* webpackChunkName: "" */))
const _73be8d78 = () => interopDefault(import('../modules/ggk_module/pages/base/help/index.vue' /* webpackChunkName: "" */))
const _0bbe139c = () => interopDefault(import('../modules/ggk_module/pages/base/checks/list.vue' /* webpackChunkName: "" */))
const _51f6f2bd = () => interopDefault(import('../modules/ggk_module/pages/base/checks/app.vue' /* webpackChunkName: "" */))
const _3d6053fc = () => interopDefault(import('../modules/ggk_module/pages/base/auth/login.vue' /* webpackChunkName: "" */))
const _432c3151 = () => interopDefault(import('../modules/ggk_module/pages/base/application/monitor/_appId.vue' /* webpackChunkName: "" */))
const _ac124786 = () => interopDefault(import('../modules/ggk_module/pages/base/application/list.vue' /* webpackChunkName: "" */))
const _050e12d8 = () => interopDefault(import('../modules/ggk_module/pages/base/3d/index.vue' /* webpackChunkName: "" */))
const _0c5aef48 = () => interopDefault(import('../modules/ggk_module/pages/arm/registries.vue' /* webpackChunkName: "" */))
const _32d62b90 = () => interopDefault(import('../modules/ggk_module/pages/arm/login.vue' /* webpackChunkName: "" */))
const _594e3c7c = () => interopDefault(import('../modules/ggk_module/pages/arm/help.vue' /* webpackChunkName: "" */))
const _99ef5fd6 = () => interopDefault(import('../modules/ggk_module/pages/arm/forgotPass.vue' /* webpackChunkName: "" */))
const _63cd25f2 = () => interopDefault(import('../modules/ggk_module/pages/arm/expert/list.vue' /* webpackChunkName: "" */))
const _60a0a8c2 = () => interopDefault(import('../modules/ggk_module/pages/arm/expert/app/_appId.vue' /* webpackChunkName: "" */))
const _3caad0d1 = () => interopDefault(import('../modules/ggk_module/pages/arm/checks/list.vue' /* webpackChunkName: "" */))
const _72490c55 = () => interopDefault(import('../modules/ggk_module/pages/arm/analytics.vue' /* webpackChunkName: "" */))
const _0d9514d4 = () => interopDefault(import('../modules/ggk_module/pages/arm/admin/system/technicalWorks.vue' /* webpackChunkName: "" */))
const _4aa84e4d = () => interopDefault(import('../modules/ggk_module/pages/arm/admin/system/registryAccess/list.vue' /* webpackChunkName: "" */))
const _20c58238 = () => interopDefault(import('../modules/ggk_module/pages/arm/admin/system/registryAccess/edit/_id.vue' /* webpackChunkName: "" */))
const _0b0fdbc5 = () => interopDefault(import('../modules/ggk_module/pages/arm/admin/system/registryAccess/delete/_id.vue' /* webpackChunkName: "" */))
const _86c4942a = () => interopDefault(import('../modules/ggk_module/pages/arm/admin/system/registryAccess/create.vue' /* webpackChunkName: "" */))
const _ead98f56 = () => interopDefault(import('../modules/ggk_module/pages/arm/3d/index.vue' /* webpackChunkName: "" */))
const _672e71c2 = () => interopDefault(import('../uicore/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a7caffe6 = () => interopDefault(import('../uicore/pages/base/check_doc/_fileId.vue' /* webpackChunkName: "pages/base/check_doc/_fileId" */))
const _60687b02 = () => interopDefault(import('../uicore/pages/arm/resetPass/_token.vue' /* webpackChunkName: "pages/arm/resetPass/_token" */))
const _2a940838 = () => interopDefault(import('../uicore/pages/base/service/list/_parentId.vue' /* webpackChunkName: "pages/base/service/list/_parentId" */))
const _7b71d0fb = () => interopDefault(import('../uicore/pages/base/service/info/_tsrId.vue' /* webpackChunkName: "pages/base/service/info/_tsrId" */))
const _173c30b5 = () => interopDefault(import('../uicore/pages/base/auth/resetPass/_token.vue' /* webpackChunkName: "pages/base/auth/resetPass/_token" */))
const _a8dd541c = () => interopDefault(import('../uicore/pages/base/application/process/_asId.vue' /* webpackChunkName: "pages/base/application/process/_asId" */))
const _6114f57a = () => interopDefault(import('../uicore/pages/arm/service/list/_parentId.vue' /* webpackChunkName: "pages/arm/service/list/_parentId" */))
const _64c167d0 = () => interopDefault(import('../uicore/pages/arm/service/info/_tsrId.vue' /* webpackChunkName: "pages/arm/service/info/_tsrId" */))
const _07e7b907 = () => interopDefault(import('../uicore/pages/arm/application/process/_asId.vue' /* webpackChunkName: "pages/arm/application/process/_asId" */))
const _1564c027 = () => interopDefault(import('../uicore/pages/arm/application/monitor/_appId.vue' /* webpackChunkName: "pages/arm/application/monitor/_appId" */))
const _d18afcde = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/_tsrId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/_tsrId" */))
const _09d7abb3 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/employee/_empId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/employee/_empId" */))
const _54caf754 = () => interopDefault(import('../uicore/pages/base/application/result/appId.vue' /* webpackChunkName: "pages/base/application/result/appId" */))
const _a934e61c = () => interopDefault(import('../uicore/pages/arm/application/view/asId.vue' /* webpackChunkName: "pages/arm/application/view/asId" */))
const _2796043f = () => interopDefault(import('../uicore/pages/arm/admin/system/route.vue' /* webpackChunkName: "pages/arm/admin/system/route" */))
const _729d6e4e = () => interopDefault(import('../uicore/pages/arm/admin/system/registryLayers.vue' /* webpackChunkName: "pages/arm/admin/system/registryLayers" */))
const _69df0498 = () => interopDefault(import('../uicore/pages/arm/admin/system/list.vue' /* webpackChunkName: "pages/arm/admin/system/list" */))
const _d4d4b288 = () => interopDefault(import('../uicore/pages/arm/admin/system/layers.vue' /* webpackChunkName: "pages/arm/admin/system/layers" */))
const _1e60e45a = () => interopDefault(import('../uicore/pages/arm/admin/system/control.vue' /* webpackChunkName: "pages/arm/admin/system/control" */))
const _7779fe38 = () => interopDefault(import('../uicore/pages/arm/admin/system/calendar.vue' /* webpackChunkName: "pages/arm/admin/system/calendar" */))
const _8eab00d2 = () => interopDefault(import('../uicore/pages/arm/admin/system/app.vue' /* webpackChunkName: "pages/arm/admin/system/app" */))
const _51b5ceb8 = () => interopDefault(import('../uicore/pages/arm/admin/org/service.vue' /* webpackChunkName: "pages/arm/admin/org/service" */))
const _d8b86962 = () => interopDefault(import('../uicore/pages/arm/admin/org/employee.vue' /* webpackChunkName: "pages/arm/admin/org/employee" */))
const _6f43b990 = () => interopDefault(import('../uicore/pages/arm/admin/org/app.vue' /* webpackChunkName: "pages/arm/admin/org/app" */))
const _71d9de6f = () => interopDefault(import('../uicore/pages/base/auth/register.vue' /* webpackChunkName: "pages/base/auth/register" */))
const _117b928d = () => interopDefault(import('../uicore/pages/base/auth/profile.vue' /* webpackChunkName: "pages/base/auth/profile" */))
const _380d77c0 = () => interopDefault(import('../uicore/pages/base/auth/forgotPass.vue' /* webpackChunkName: "pages/base/auth/forgotPass" */))
const _5e9442da = () => interopDefault(import('../uicore/pages/arm/application/list.vue' /* webpackChunkName: "pages/arm/application/list" */))
const _67122ede = () => interopDefault(import('../uicore/pages/arm/application/income.vue' /* webpackChunkName: "pages/arm/application/income" */))
const _48227689 = () => interopDefault(import('../uicore/pages/oldAppsList/employee.vue' /* webpackChunkName: "pages/oldAppsList/employee" */))
const _1c85b692 = () => interopDefault(import('../uicore/pages/oldAppsList/applicant.vue' /* webpackChunkName: "pages/oldAppsList/applicant" */))
const _5bea3826 = () => interopDefault(import('../uicore/pages/base/registries.vue' /* webpackChunkName: "pages/base/registries" */))
const _43bd3045 = () => interopDefault(import('../uicore/pages/base/geoportal/index.vue' /* webpackChunkName: "pages/base/geoportal/index" */))
const _a21a6eca = () => interopDefault(import('../uicore/pages/base/faq.vue' /* webpackChunkName: "pages/base/faq" */))
const _63ecf583 = () => interopDefault(import('../uicore/pages/arm/profile.vue' /* webpackChunkName: "pages/arm/profile" */))
const _4aab799a = () => interopDefault(import('../uicore/pages/arm/geoportal/index.vue' /* webpackChunkName: "pages/arm/geoportal/index" */))
const _0dbd9a16 = () => interopDefault(import('../uicore/pages/Test.vue' /* webpackChunkName: "pages/Test" */))
const _238a60ac = () => interopDefault(import('../uicore/pages/arm/index.vue' /* webpackChunkName: "pages/arm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ggkauth",
    component: _73bcc816,
    name: "ggkauth"
  }, {
    path: "/base/sso",
    component: _714a6402,
    name: "base-sso"
  }, {
    path: "/base/reg_app/get/:id",
    component: _1eff4c50,
    name: "base-reg_app-get-id"
  }, {
    path: "/base",
    component: _bc816d7c,
    name: "base"
  }, {
    path: "/base/help",
    component: _73be8d78,
    name: "base-help"
  }, {
    path: "/base/checks/list",
    component: _0bbe139c,
    name: "base-checks-list"
  }, {
    path: "/base/checks/app",
    component: _51f6f2bd,
    name: "base-checks-app"
  }, {
    path: "/base/auth/login",
    component: _3d6053fc,
    name: "base-auth-login"
  }, {
    path: "/base/application/monitor/:appId",
    component: _432c3151,
    name: "base-application-monitor-appId"
  }, {
    path: "/base/application/list",
    component: _ac124786,
    name: "base-application-list"
  }, {
    path: "/base/3d",
    component: _050e12d8,
    name: "base-3d"
  }, {
    path: "/arm/registries",
    component: _0c5aef48,
    name: "arm-registries"
  }, {
    path: "/arm/login",
    component: _32d62b90,
    name: "arm-login"
  }, {
    path: "/arm/help",
    component: _594e3c7c,
    name: "arm-help"
  }, {
    path: "/arm/forgotPass",
    component: _99ef5fd6,
    name: "arm-forgotPass"
  }, {
    path: "/arm/expert/list",
    component: _63cd25f2,
    name: "arm-expert-list"
  }, {
    path: "/arm/expert/app/:appId",
    component: _60a0a8c2,
    name: "arm-expert-app-appId"
  }, {
    path: "/arm/checks/list",
    component: _3caad0d1,
    name: "arm-checks-list"
  }, {
    path: "/arm/analytics",
    component: _72490c55,
    name: "arm-analytics"
  }, {
    path: "/arm/admin/system/technicalWorks",
    component: _0d9514d4,
    name: "arm-admin-system-technicalWorks"
  }, {
    path: "/arm/admin/system/registryAccess/list",
    component: _4aa84e4d,
    name: "arm-admin-system-registryAccess-list"
  }, {
    path: "/arm/admin/system/registryAccess/edit/:id",
    component: _20c58238,
    name: "arm-admin-system-registryAccess-edit-id"
  }, {
    path: "/arm/admin/system/registryAccess/delete/:id",
    component: _0b0fdbc5,
    name: "arm-admin-system-registryAccess-delete-id"
  }, {
    path: "/arm/admin/system/registryAccess/create",
    component: _86c4942a,
    name: "arm-admin-system-registryAccess-create"
  }, {
    path: "/arm/3d",
    component: _ead98f56,
    name: "arm-3d"
  }, {
    path: "/",
    component: _672e71c2,
    name: "index"
  }, {
    path: "/base/check_doc/:fileId?",
    component: _a7caffe6,
    name: "base-check_doc-fileId"
  }, {
    path: "/arm/resetPass/:token?",
    component: _60687b02,
    name: "arm-resetPass-token"
  }, {
    path: "/base/service/list/:parentId?",
    component: _2a940838,
    name: "base-service-list-parentId"
  }, {
    path: "/base/service/info/:tsrId?",
    component: _7b71d0fb,
    name: "base-service-info-tsrId"
  }, {
    path: "/base/auth/resetPass/:token?",
    component: _173c30b5,
    name: "base-auth-resetPass-token"
  }, {
    path: "/base/application/process/:asId?",
    component: _a8dd541c,
    name: "base-application-process-asId"
  }, {
    path: "/arm/service/list/:parentId?",
    component: _6114f57a,
    name: "arm-service-list-parentId"
  }, {
    path: "/arm/service/info/:tsrId?",
    component: _64c167d0,
    name: "arm-service-info-tsrId"
  }, {
    path: "/arm/application/process/:asId?",
    component: _07e7b907,
    name: "arm-application-process-asId"
  }, {
    path: "/arm/application/monitor/:appId?",
    component: _1564c027,
    name: "arm-application-monitor-appId"
  }, {
    path: "/arm/admin/org/ws/:tsrId?",
    component: _d18afcde,
    name: "arm-admin-org-ws-tsrId"
  }, {
    path: "/arm/admin/org/ws/employee/:empId?",
    component: _09d7abb3,
    name: "arm-admin-org-ws-employee-empId"
  }, {
    path: "/base/application/result/appId",
    component: _54caf754,
    name: "base-application-result-appId"
  }, {
    path: "/arm/application/view/asId",
    component: _a934e61c,
    name: "arm-application-view-asId"
  }, {
    path: "/arm/admin/system/route",
    component: _2796043f,
    name: "arm-admin-system-route"
  }, {
    path: "/arm/admin/system/registryLayers",
    component: _729d6e4e,
    name: "arm-admin-system-registryLayers"
  }, {
    path: "/arm/admin/system/list",
    component: _69df0498,
    name: "arm-admin-system-list"
  }, {
    path: "/arm/admin/system/layers",
    component: _d4d4b288,
    name: "arm-admin-system-layers"
  }, {
    path: "/arm/admin/system/control",
    component: _1e60e45a,
    name: "arm-admin-system-control"
  }, {
    path: "/arm/admin/system/calendar",
    component: _7779fe38,
    name: "arm-admin-system-calendar"
  }, {
    path: "/arm/admin/system/app",
    component: _8eab00d2,
    name: "arm-admin-system-app"
  }, {
    path: "/arm/admin/org/service",
    component: _51b5ceb8,
    name: "arm-admin-org-service"
  }, {
    path: "/arm/admin/org/employee",
    component: _d8b86962,
    name: "arm-admin-org-employee"
  }, {
    path: "/arm/admin/org/app",
    component: _6f43b990,
    name: "arm-admin-org-app"
  }, {
    path: "/base/auth/register",
    component: _71d9de6f,
    name: "base-auth-register"
  }, {
    path: "/base/auth/profile",
    component: _117b928d,
    name: "base-auth-profile"
  }, {
    path: "/base/auth/forgotPass",
    component: _380d77c0,
    name: "base-auth-forgotPass"
  }, {
    path: "/arm/application/list",
    component: _5e9442da,
    name: "arm-application-list"
  }, {
    path: "/arm/application/income",
    component: _67122ede,
    name: "arm-application-income"
  }, {
    path: "/oldAppsList/employee",
    component: _48227689,
    name: "oldAppsList-employee"
  }, {
    path: "/oldAppsList/applicant",
    component: _1c85b692,
    name: "oldAppsList-applicant"
  }, {
    path: "/base/registries",
    component: _5bea3826,
    name: "base-registries"
  }, {
    path: "/base/geoportal",
    component: _43bd3045,
    name: "base-geoportal"
  }, {
    path: "/base/faq",
    component: _a21a6eca,
    name: "base-faq"
  }, {
    path: "/arm/profile",
    component: _63ecf583,
    name: "arm-profile"
  }, {
    path: "/arm/geoportal",
    component: _4aab799a,
    name: "arm-geoportal"
  }, {
    path: "/Test",
    component: _0dbd9a16,
    name: "Test"
  }, {
    path: "/arm",
    component: _238a60ac,
    name: "arm"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
