import {
  FETCH_3D_MODELS_FEATURE_AVAILABILITY,
  LOAD_IFC_MODEL,
  INIT_IFC_API,
  GET_MODELS_LIST,
  TOGGLE_MODEL,
  UPDATE_MODEL_POSITION,
  HANDLE_MOVE_MODEL,
  SELECT_MODEL,
  BOOT_3D_MODELS_MODULE,
} from "./action-types";

import {
  SET_IFC_API,
  SET_3D_MODELS_ACCESS,
  SET_MODELS_LIST,
  SET_LIST_LOADING,
  SET_SELECTED_MODEL_ID,
  SET_MODULE_BOOTED,
} from './mutation-types';

import ifcUtils from "./ifcUtils";

import { IfcAPI } from "web-ifc";
import {
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Cartesian3,
  Transforms,
  Matrix4,
} from "cesium";

export default {
  [FETCH_3D_MODELS_FEATURE_AVAILABILITY]({ commit, state, rootState }) {
    return this.$axios
      .$get("api/3d-model/availability")
      .then((response) => {
        commit(SET_3D_MODELS_ACCESS, response == 1);
      })
  },

  [GET_MODELS_LIST]({ commit, state, rootState }) {
    commit(SET_LIST_LOADING, true);

    return this.$axios
      .$get("api/3d-model/list")
      .then((response) => {
        commit(SET_MODELS_LIST, response);
      })
      .catch(() => alert("Ошибка загрузки списка моделей!"))
      .finally(() => commit(SET_LIST_LOADING, false));
  },

  [TOGGLE_MODEL]({ commit, dispatch, state, getters, rootState }, modelId) {
    return dispatch(LOAD_IFC_MODEL, modelId).then(() => {
      if (getters.isModelShown(modelId))
        getters.getModelPrimitive(modelId).show = false;
      else
        getters.getModelPrimitive(modelId).show = true;
    });
  },

  [BOOT_3D_MODELS_MODULE]({ commit, dispatch, state, rootState }) {
    if (!state.ifcApi)
      dispatch(INIT_IFC_API);

    const scene = rootState.cesium.olCesium.getCesiumScene();
    const handler = new ScreenSpaceEventHandler(scene.canvas);

    handler.setInputAction(
      (movement) => dispatch(HANDLE_MOVE_MODEL, movement),
      ScreenSpaceEventType.LEFT_CLICK
    );

    commit(SET_MODULE_BOOTED);
  },

  [INIT_IFC_API]({ commit, state, rootState }) {
    commit(SET_IFC_API, new IfcAPI());
    state.ifcApi.SetWasmPath("/wasm/", true);
    state.ifcApiReadyStatus = state.ifcApi.Init();
  },

  [UPDATE_MODEL_POSITION](
    { commit, state, getters, rootState },
    {modelId, cartesian3}
  ) {
    const primitiveCollection = getters.getModelPrimitive(modelId);

    for (let i = 0; i < primitiveCollection.length; i++) {
      const modelMatrix = primitiveCollection.get(i).modelMatrix;
      Matrix4.setTranslation(modelMatrix, cartesian3, modelMatrix);
    }
  },

  [LOAD_IFC_MODEL]({ commit, dispatch, state, getters, rootState }, modelId) {
    if (getters.isModelLoaded(modelId))
      return;

    if (!state.moduleBooted)
      dispatch(BOOT_3D_MODELS_MODULE);

    return this.$axios
      .$get("api/3d-model/get", {
        responseType: "arraybuffer",
        params: {
          id: modelId,
          as_file: 1,
        }
      })
      .then(async (response) => {
        const rawIfcModel = new Uint8Array(response);
        await state.ifcApiReadyStatus;
        return rawIfcModel;
      })
      .then((rawIfcModel) => {
        const primitiveCollection = ifcUtils.ifcModelToPrimitiveCollection(
          state.ifcApi,
          rawIfcModel
        );
        primitiveCollection.show = false;

        state.modelPrimitives[modelId] = primitiveCollection;

        // sets default position (in Astana)
        const modelMatrix = Transforms.eastNorthUpToFixedFrame(
          Cartesian3.fromDegrees(71.437, 51.122, 0)
        );
        for (let i = 0; i < primitiveCollection.length; i++) {
          primitiveCollection.get(i).modelMatrix = modelMatrix;
        }

        const scene = rootState.cesium.olCesium.getCesiumScene();
        scene.primitives.add(primitiveCollection);
      })
      .catch(() => alert("Ошибка загрузки 3D модели!"));
  },

  [HANDLE_MOVE_MODEL]({ commit, dispatch, state, rootState }, movement) {
    const scene = rootState.cesium.olCesium.getCesiumScene();
    const cartesian3 = scene.camera.pickEllipsoid(
      movement.position,
      scene.globe.ellipsoid
    );

    if (!cartesian3)
      return;

    if (!state.selectedModelId) {
      return dispatch(SELECT_MODEL, {
        cartesian2: movement.position,
      });
    }

    dispatch(UPDATE_MODEL_POSITION, {
      modelId: state.selectedModelId,
      cartesian3: cartesian3,
    });
    commit(SET_SELECTED_MODEL_ID, null);
  },

  [SELECT_MODEL](
    { commit, dispatch, state, getters, rootState },
    {cartesian2}
  ){
    const pickedPrimitive = rootState.cesium.olCesium
      .getCesiumScene()
      .pick(cartesian2)
      ?.primitive;

    if (!pickedPrimitive)
      return;

    for (
      let [modelId, primitivesCollection]
      of Object.entries(state.modelPrimitives)
    ) {
      if (primitivesCollection.contains(pickedPrimitive)) {
        commit(SET_SELECTED_MODEL_ID, modelId);
        return;
      }
    }
  },
};
