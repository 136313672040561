//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations } from "vuex";
import { mask } from "vue-the-mask";
import { FETCH_3D_MODELS_FEATURE_AVAILABILITY } from "@ggk_module/store/models3d/action-types";
export default {
  layout: "base",
  middleware: "isApplicantLoggedIn",
  data() {
    return {
      iin: null,
      bin: null,
      password: null,
      activeTab: "individual",
      xml: "<authorization></authorization>",
      sign: null,
      errors: null,
      redirectEgovInProcess: false
    };
  },
  directives: { mask },
  computed: {
    currentLocale() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
    },
  },
  methods: {
    ...mapActions("models3d", [FETCH_3D_MODELS_FEATURE_AVAILABILITY]),
    ...mapActions("eds", ["signCMS", "signXml"]),
    ...mapMutations("pageAccess", ["set_expert_access", "set_check_access"]),
    closeModal() {
      this.$emit("close");
    },
    openForgotPassModal() {
      this.$emit("openForgotPass");
    },
    redirectToRegister() {
      this.$emit("redirectToRegister");
    },
    redirectToLogin() {
      this.$router.push("/arm/login");
      // ${process.env.BASE_URL}
    },
    redirectToEgov() {
      const query = new URLSearchParams({
        response_type: 'code',
        client_id: 'GGK_SSO',
        redirect_uri: `${process.env.BASE_URL}/base/sso`,
        state: Math.random().toString(36).slice(2, 7),
        scope: 'user:basic:read user:phone:read user:email:read',
        lang: 'kk'
      }).toString();

      window.location.href = `https://idp.egov.kz/idp/oauth/authorize?${query}`;
    },

    async send() {
      let data = {
        iin: this.iin,
        password: this.password,
      };
      if (this.activeTab == "entity") {
        data.bin = this.bin;
      }
      await this.$auth
        .loginWith(this.activeTab, {
          data,
        })
        .then((resp) => {
          if (resp?.status == 200) {
            this[FETCH_3D_MODELS_FEATURE_AVAILABILITY]();
            this.checkAccess();
            this.$emit("login");
            this.$nuxt.$emit("succesLogin");
            this.$router.push(this.$route.query?.redirect || "/base/");
            this.closeModal();
          }
        })
        .catch((error) => {
          this.errors = error.response?.data?.message;
        });
    },
    edsSend() {
      this.signXml(this.xml).then((sign) => {
        this.sign = sign;
        this.$auth
          .loginWith(`${this.activeTab}Eds`, {
            data: {
              sign: this.sign,
            },
          })
          .then((resp) => {
            if (resp == 1) {
              this[FETCH_3D_MODELS_FEATURE_AVAILABILITY]();
              this.checkAccess();
              this.$emit("login");
              this.closeModal();
            }
          })
          .catch((error) => {
            this.errors = error.response.data.message;
          });
      });
    },
    checkAccess() {
      this.$axios.$get("api/reg_app/expert/is_expert").then((res) => {
        if (res == 1) {
          this.set_expert_access(true);
        }
      });
      this.$axios.$get("api/reg_app/access").then((res) => {
        if (res == 1) {
          this.set_check_access(true);
        }
      });
    },
  },
};
