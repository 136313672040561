import {
  Primitive,
  PrimitiveCollection,
  PerInstanceColorAppearance,
  ShadowMode,
  Matrix3,
  Matrix4,
  Math,
  GeometryInstance,
  Geometry,
  ComponentDatatype,
  PrimitiveType,
  BoundingSphere,
  ColorGeometryInstanceAttribute,
  GeometryAttribute,
} from "cesium";

function ifcGeometryToCesiumGeometryInstance(
  vertices,
  indices,
  flatTransformation,
  color,
) {
  const coordinates = new Float64Array(vertices.length / 2);
  const normals     = new Float32Array(vertices.length / 2);
  const bbox = {
    x: {max: -Infinity, min: Infinity},
    y: {max: -Infinity, min: Infinity},
    z: {max: -Infinity, min: Infinity},
  };

  for (let j = 0; j < vertices.length; j += 6) {
    let coords = {
      x: vertices[j + 0],
      y: vertices[j + 1],
      z: vertices[j + 2],
    };
    normals[j / 2 + 0] = vertices[j + 3];
    normals[j / 2 + 1] = vertices[j + 4];
    normals[j / 2 + 2] = vertices[j + 5];

    coordinates[j / 2 + 0] = coords.x;
    coordinates[j / 2 + 1] = coords.y;
    coordinates[j / 2 + 2] = coords.z;

    ['x','y','z'].forEach((axis) => {
      if (coords[axis] < bbox[axis].min)
        bbox[axis].min = coords[axis];
      if (coords[axis] > bbox[axis].max)
        bbox[axis].max = coords[axis];
    });
  }

  const positionAttribute = new GeometryAttribute({
    componentDatatype: ComponentDatatype.DOUBLE,
    componentsPerAttribute: 3,
    values: coordinates,
  });

  const normalAttribute = new GeometryAttribute({
    componentDatatype: ComponentDatatype.FLOAT,
    componentsPerAttribute: 3,
    values: normals,
    normalize: true,
  });

  const geometry = new Geometry({
    indices,
    primitiveType: PrimitiveType.TRIANGLES,
    boundingSphere: BoundingSphere.fromVertices(coordinates),
    attributes: {
      position: positionAttribute,
      normal: normalAttribute,
    },
  });
  geometry.bbox = bbox;

  const rotationMatrix = Matrix4.fromRotationTranslation(
    Matrix3.fromRotationX(
      Math.toRadians(90.0)
    )
  );

  const modelMatrix = Matrix4.multiply(
    rotationMatrix,
    flatTransformation,
    new Matrix4()
  );

  return new GeometryInstance({
    modelMatrix: modelMatrix,
    geometry: geometry,
    attributes: {
      color: new ColorGeometryInstanceAttribute(
        color.x, color.y, color.z, color.w
      ),
    }
  });
}

function ifcModelToGeometriesArray(
  ifcApi,
  ifcModelID,
  isTranslucent = false
) {
  let geometries = [];

  ifcApi.StreamAllMeshes(ifcModelID, (mesh) => {
    for (let i = 0; i < mesh.geometries.size(); i++) {
      const placedGeometry = mesh.geometries.get(i);

      if (isTranslucent && placedGeometry.color.w === 1)
        continue;
      else if (!isTranslucent && placedGeometry.color.w !== 1)
        continue;

      const ifcGeometry = ifcApi.GetGeometry(
        ifcModelID,
        placedGeometry.geometryExpressID
      );
      const ifcVertices = ifcApi.GetVertexArray(
        ifcGeometry.GetVertexData(),
        ifcGeometry.GetVertexDataSize()
      );
      const ifcIndices = ifcApi.GetIndexArray(
        ifcGeometry.GetIndexData(),
        ifcGeometry.GetIndexDataSize()
      );
      ifcGeometry.delete();

      geometries.push(
        ifcGeometryToCesiumGeometryInstance(
          ifcVertices,
          ifcIndices,
          placedGeometry.flatTransformation,
          placedGeometry.color,
        )
      );
    };
  });

  return geometries;
};

const exports = {};

exports.ifcModelToPrimitiveCollection = function (
  ifcApi,
  rawIfcModel
) {
  const ifcModelID = ifcApi.OpenModel(rawIfcModel);

  let primitiveOptions = {
    releaseGeometryInstances: true,
    allowPicking: true,
    asynchronous: false,
    shadows: ShadowMode.DISABLED,
  };

  const primitiveCollection = new PrimitiveCollection();

  ['opaque', 'translucent'].forEach((colorType) => {
    const isTranslucent = colorType === 'translucent';
    const geometryInstances = ifcModelToGeometriesArray(
      ifcApi,
      ifcModelID,
      isTranslucent
    );

    primitiveOptions.geometryInstances = geometryInstances;
    primitiveOptions.appearance = new PerInstanceColorAppearance({
      translucent: isTranslucent
    });

    const primitive = new Primitive(primitiveOptions);
    primitive.boundingSphere = BoundingSphere.fromPoints(
      geometryInstances.map((gInst) => gInst.geometry.boundingSphere.center)
    );

    primitiveCollection.add(primitive);
  });

  return primitiveCollection;
};

export default exports;