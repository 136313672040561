import {
  INIT_MAP
} from "./action-types";
import {
  SET_MAP
} from "./mutation-types";


import olInitMap from "~/assets/js/olInitMap";
import TileLayer from "ol/layer/Tile.js";
import TileWMS from "ol/source/TileWMS.js";
import XYZSource from "ol/source/XYZ.js";
const createLayer = (layer) =>
  new TileLayer({
    source: new TileWMS({
      url: `${process.env.GEOSERVER_URL}/wms`,
      params: {
        LAYERS: layer,
        TILED: true,
        VERSION: "1.1.1",
        SRS: "EPSG:3857",
        BBOX: "{bbox-epsg-3857}",
        WIDTH: 256,
        HEIGHT: 256,
      },
      serverType: "geoserver",
    }),
    zIndex: 97,
  });
export default {
  async [INIT_MAP]({ commit }, { el }) {
    let map = olInitMap({ el });
    let twogisLayer = new TileLayer({
      source: new XYZSource({
        urls: [
          "//tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1.4&r=g&ts=online_hd",
          "//tile1.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1.4&r=g&ts=online_hd",
          "//tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1.4&r=g&ts=online_hd",
          "//tile3.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1.4&r=g&ts=online_hd",
          "//tile4.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1.4&r=g&ts=online_hd",
        ],
      }),
    });
    let baseLayers = [
      {
        name: "gp_func_zones_layer",
        olLayer: createLayer("gp_func_zones_layer")
      },
      {
        name: "gp_red_lines_active",
        olLayer:  createLayer("gp_red_lines_active")
      }
    ];
    map.addLayer(twogisLayer);
    baseLayers.forEach((layer) => {
      map.addLayer(layer.olLayer);
    })
    commit(SET_MAP, map);
  },
};
