import {
  SET_IFC_API,
  SET_3D_MODELS_ACCESS,
  SET_MODELS_LIST,
  SET_LIST_LOADING,
  SET_SELECTED_MODEL_ID,
  SET_MODULE_BOOTED,
} from './mutation-types';

export default {
  [SET_IFC_API](state, ifcApi) {
    state.ifcApi = ifcApi;
  },
  [SET_3D_MODELS_ACCESS](state, isAccessible) {
    state.is3DModelsFeatureAccessible = isAccessible;
  },
  [SET_MODELS_LIST](state, models) {
    state.modelsList = models;
  },
  [SET_LIST_LOADING](state, isListLoading) {
    state.isListLoading = isListLoading;
  },
  [SET_SELECTED_MODEL_ID](state, selectedModelId) {
    state.selectedModelId = selectedModelId;
  },
  [SET_MODULE_BOOTED](state) {
    state.moduleBooted = true;
  },
}
