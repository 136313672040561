//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      projectName: process.env.projectName,
      notificationShow: false,
      notificationNameRU: null,
      notificationNameKK: null,
    };
  },
  head() {
    return {
      link: [{ rel: "stylesheet", href: "/css/base.css" }],
      title: this.projectName[this.$i18n.locale],
      meta: [
        {
          hid: 'google',
          name: 'google',
          value: 'notranslate'
        }
      ]
    };
  },
  created() {
    this.$axios
    .$get("api/help_category/maintenance")
    .then((data) => {
      this.notificationShow = data?.active
      this.notificationNameRU = data?.name_ru
      this.notificationNameKK = data?.name_kk
    });
  },
  computed: {
    conditionForGrayBg(){
      let routes = ["base-auth-profile", "base-application-list", "base-application-process-asId", "base-application-monitor-appId"]
      if(routes.includes(this.$nuxt.$route.name)){
        return true;
      }
    },
    locale() {
      return this.$i18n.locale;
    },
  }
};
