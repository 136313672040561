export default {
  getModelPrimitive: (state) => (modelId) => {
    return state.modelPrimitives[modelId.toString()];
  },
  isModelShown: (state, getters) => (modelId) => {
    return !!getters.getModelPrimitive(modelId)?.show;
  },
  isModelLoaded: (state) => (modelId) => {
    return Object.keys(state.modelPrimitives)
      .includes(modelId.toString());
  },
};
