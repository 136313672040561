import { render, staticRenderFns } from "./TemplateModal.vue?vue&type=template&id=e31284e0&scoped=true&"
import script from "./TemplateModal.vue?vue&type=script&lang=js&"
export * from "./TemplateModal.vue?vue&type=script&lang=js&"
import style0 from "./TemplateModal.vue?vue&type=style&index=0&id=e31284e0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e31284e0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsBaseAuthorization: require('/var/www/ggk_geoportal/ui/modules/ggk_module/components/Modals/BaseAuthorization.vue').default,ModalsRegistration: require('/var/www/ggk_geoportal/ui/modules/ggk_module/components/Modals/Registration.vue').default,ModalsBaseForgotPass: require('/var/www/ggk_geoportal/ui/modules/ggk_module/components/Modals/BaseForgotPass.vue').default})
