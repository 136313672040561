import {
  SET_LAYERS_LOADING,
  SET_LAYERS,
  ADD_ACTIVE_LAYER,
  REMOVE_ACTIVE_LAYER
} from './mutation-types'

export default {
  [SET_LAYERS_LOADING](state, isLoading) {
    state.layersLoading = isLoading;
  },
  [SET_LAYERS](state, layers) {
    state.layers = layers;
  },
  [ADD_ACTIVE_LAYER](state, layerId) {
    state.activeLayers.push(layerId);
  },
  [REMOVE_ACTIVE_LAYER](state, layerId) {
    const idx = state.activeLayers.findIndex(i => i === layerId);
    state.activeLayers.splice(idx, 1);
  }
}