export default () => ({
    moduleBooted: false,
    is3DModelsFeatureAccessible: false,
    ifcApi: null,
    ifcApiReadyStatus: null, // Promise
    isListLoading: false,
    modelsList: [],
    modelPrimitives: {},
    selectedModelId: null,
});
