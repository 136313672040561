//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mask } from "vue-the-mask";
import { mapGetters, mapActions } from "vuex";
export default {
  layout: "base",
  directives: { mask },
  data() {
    return {
      email: null,
      phone: null,
      password: null,
      password_confirmation: null,
      role: false,
      activeTab: "individual",
      xml: "<registration></registration>",
      errors: null,
    };
  },
  computed: {
    currentLocale() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
    },
  },
  methods: {
    ...mapActions("eds", ["signCMS", "signXml"]),
    closeModal() {
      this.$emit("close");
    },
    redirectToLogin() {
      this.$router.push("/arm/login");
    },
    send() {
      let response = 0;
      this.errors = null;
      this.$axios.$get("/sanctum/csrf-cookie").then((res) => {
        this.signXml(this.xml).then((sign) => {
          const params = {
            phone: this.phone,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            sign: sign,
          };
          this.$axios
            .$post(`api/register/eds/${this.activeTab}`, params)
            .then((r) => {
              response = this.$auth.loginWith(this.activeTab, {
                data: {
                  iin: r.iin,
                  password: this.password,
                  bin: r.bin,
                },
              });
              if (response == 1) {
                this.$router.push("/base/");
              }
            })
            .catch((error) => {
              this.errors = error.response.data.errors;
            });
        });
      });
    },
    redirectToAuth() {
      this.$emit("redirectToAuth");
    },
  },
};
