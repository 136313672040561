//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    modalComponent: String,
  },
  data() {
    return {
      activeComponent: this.modalComponent
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    
  },
};
