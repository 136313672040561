//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
export default {
  layout: "base",
  middleware: "isApplicantLoggedIn",
  data() {
    return {
      iin: null,
      bin: null,
      email: null,
      activeTab: "individual",
      result: false,
    };
  },
  directives: { mask },
  computed: {
    currentLocale() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
    },
  },
  methods: {
    ...mapActions("eds", ["signCMS", "signXml"]),

    back() {
      this.$emit("openAuthModal");
    },
    send() {
      if (this.activeTab == "individual") {
        const params = {
          iin: this.iin,
          email: this.email,
        };
        this.$axios.$get("/sanctum/csrf-cookie").then((res) => {
          this.$axios
            .$post("api/password/forget/individual", params)
            .then((res) => {
              if (res == "success") {
                this.result = true;
              }
            });
        });
      } else {
        const params = {
          iin: this.iin,
          bin: this.bin,
          email: this.email,
        };
        this.$axios.$get("/sanctum/csrf-cookie").then((res) => {
          this.$axios
            .$post("api/password/forget/entity", params)
            .then((res) => {
              if (res == "success") {
                this.result = true;
              }
            });
        });
      }
    },
  },
};
